import { Inject, Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
  HttpEvent,
  HttpParams,
} from "@angular/common/http";

import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

// tslint:disable-next-line:nx-enforce-module-boundaries
import { State } from "apps/kargoru-web/src/app/store/reducers";
// tslint:disable-next-line:nx-enforce-module-boundaries
import * as globalErrorActions from "apps/kargoru-web/src/app/store/actions/global-error.actions";
// tslint:disable-next-line:nx-enforce-module-boundaries
import { isProblemDetailsError } from "apps/kargoru-web/src/app/core/models/error/validation-error";
// tslint:disable-next-line:nx-enforce-module-boundaries
import { RequestOptions } from "apps/kargoru-web/src/app/core/models/http/request-options";
// tslint:disable-next-line:nx-enforce-module-boundaries
import { Environment } from "apps/kargoru-web/src/environments/environment";
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ENVIRONMENT } from "apps/kargoru-web/src/app/core/constants/global-tokens";
import { ApiSelector } from "../../../../../../../../libs/enums/api-selector";
import { T } from "@angular/cdk/keycodes";

@Injectable()
export class ApiService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    protected http: HttpClient,
    private store: Store<State>
  ) {}

  public delete<T>(
    url: string,
    options?: RequestOptions & { observe?: "response" },
    showValidationErrorMessage?: boolean,
    api?: ApiSelector
  ): Observable<HttpResponse<T>>;

  public delete<T>(
    url: string,
    options?: RequestOptions & { observe?: "body" },
    showValidationErrorMessage?: boolean,
    api?: ApiSelector
  ): Observable<T>;

  public delete<T>(
    url: string,
    options?: RequestOptions & { observe?: "response" | "body" },
    showValidationErrorMessage = true,
    api = ApiSelector.AUTH
  ): Observable<any> {
    return this.http
      .delete<T>(`${this.environment[api]}/documents${url}`, options as any)
      .pipe(
        catchError((error) =>
          this.handleError(error, showValidationErrorMessage)
        )
      );
  }

  public get<T>(
    url: string,
    option?: RequestOptions,
    api?: ApiSelector
  ): Observable<T>;

  public get<T>(
    url: string,
    option?: RequestOptions & { observe: "response" },
    api?: ApiSelector
  ): Observable<HttpResponse<T>>;

  public get(
    url: string,
    option?: RequestOptions & { responseType: "arraybuffer" },
    api?: ApiSelector
  ): Observable<ArrayBuffer>;

  public get(
    url: string,
    option?: RequestOptions & { responseType: "blob" },
    api?: ApiSelector
  ): Observable<Blob>;

  public get(
    url: string,
    option?: RequestOptions & { responseType: "blob" } & {
      observe: "response";
    },
    api?: ApiSelector
  ): Observable<HttpResponse<Blob>>;

  public get<T>(
    url: string,
    option?: RequestOptions & { observe?: "body" | "events" | "response" } & {
      responseType?: "blob" | "arraybuffer";
    },
    api = ApiSelector.AUTH
  ):
    | Observable<HttpResponse<T>>
    | Observable<T>
    | Observable<ArrayBuffer>
    | Observable<Blob> {
    return this.http
      .get(`${this.environment[api]}${url}`, option as any)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public patch<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: "response" },
    api?: ApiSelector
  ): Observable<HttpResponse<T>>;

  public patch<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: "events" },
    api?: ApiSelector
  ): Observable<HttpEvent<T>>;

  public patch<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: "body" },
    api?: ApiSelector
  ): Observable<T>;

  public patch<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: "body" | "events" | "response" },
    api = ApiSelector.AUTH
  ): Observable<HttpEvent<T>> | Observable<HttpResponse<T>> | Observable<T> {
    return this.http
      .patch<T>(`${this.environment[api]}${url}`, body, options as any)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public post<T>(
    url: string,
    body: any,
    options?: RequestOptions,
    showValidationErrorMessage?: boolean,
    api?: ApiSelector
  ): Observable<T>;

  public post<T>(
    url: string,
    body: any,
    options?: RequestOptions & { responseType: "blob" } & {
      observe: "response";
    },
    showValidationErrorMessage?: boolean,
    api?: ApiSelector
  ): Observable<HttpResponse<Blob>>;

  public post<T>(
    url: string,
    body: any,
    option?: RequestOptions & { observe: "events" },
    showValidationErrorMessage?: boolean,
    api?: ApiSelector
  ): Observable<HttpEvent<T>>;

  public post<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: "body" | "events" | "response" },
    showValidationErrorMessage = true,
    api = ApiSelector.AUTH
  ): Observable<HttpEvent<T>> | Observable<HttpResponse<T>> | Observable<T> {
    return this.http
      .post<T>(`${this.environment[api]}${url}`, body, options as any)
      .pipe(
        catchError((error) =>
          this.handleError(error, showValidationErrorMessage)
        )
      );
  }

  public put<T>(
    url: string,
    body: any,
    options?: RequestOptions,
    api?: ApiSelector,
    showValidationErrorMessage?: boolean
  ): Observable<T>;

  public put<T>(
    url: string,
    body: any,
    option?: RequestOptions & { observe: "events" },
    api?: ApiSelector,
    showValidationErrorMessage?: boolean
  ): Observable<HttpEvent<T>>;

  public put<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: "events" | "response" | "body" },
    api = ApiSelector.AUTH
  ): Observable<HttpEvent<T>> | Observable<T> {
    return this.http
      .put<T>(`${this.environment[api]}${url}`, body, options as any)
      .pipe(
        catchError((error) => {
          return this.handleError(error);
        })
      );
  }

  private handleError(
    err: HttpErrorResponse,
    showValidationErrorMessage?: boolean
  ) {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log("An error occurred:", err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${err.status}, body was:`,
        typeof err.error === "string" ? "HTML body" : err.error
      );
    }

    const messageTracking =
      "Http failure response for http://localhost:4200/api/tracking/f66686bb-3f67-4b89-937c-2c785d2c48e9: 500 Internal Server Error";
    if (
      (err.status !== 422 || showValidationErrorMessage) &&
      messageTracking !== err.message
    ) {
      this.store.dispatch(
        globalErrorActions.addGlobalError({
          error:
            (typeof err.error !== "string" && { ...err.error }) || err.error,
        })
      );
    }

    return throwError((isProblemDetailsError(err.error) && err.error) || err);
  }
}
